import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ContainerCarouselExams = styled.div`
  width: 100%;
  margin-bottom: 24px;

  ${media.greaterThan('1300px')`
      width: 110%;
    
  `}
`
export const ContainerTitleCardsExams = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`

export const TitleCardContentExams = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #000e40;
  font-family: 'Dasa Sans';
  margin-bottom: 23px;
  display: flex;
`

export const ContentCarouselExams = styled.div`
  position: relative;
  min-width: 100%;

  ${media.lessThan('medium')`
    max-width: 100%;
    min-width: 0;
  `}

  .swiper-container {
    min-height: 215px;

    ${media.lessThan('medium')`
      min-height: 0;
    `}

    .swiper-wrapper {
      gap: 0;

      .swiper-filler {
        width: calc(100vw - 300px);
        display: block;
      }
    }

    .swiper-slide {
      display: grid;
      width: 312px !important;
      gap: 32px 0;

      ${media.greaterThan('medium')`
       &:not(:last-child) {
            margin-right: 2.4rem !important;
        }

        &.swiper-filler {
          display: none;
        }
      `}

      ${media.lessThan('medium')`
        gap: 16px;
        width: 100% !important;
        margin: 0 !important;

        &:not(:last-child) {
            margin-right: 0 !important;
        }

        &.swiper-filler {
          width: calc(100vw - 300px) !important;
          order: 999 !important;
        }
      `}
    }
  }

  .swiper-pagination {
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    align-content: center;
    padding: 1.2rem 0;

    &.swiper-pagination-bullets {
      bottom: 0.4rem;

      ${media.lessThan('medium')`
          bottom: 0;
          height: .8rem;
        `}
    }

    &.swiper-pagination-bullets,
    &.swiper-pagination-clickable {
      .swiper-pagination-bullet {
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        margin: 0 5px;
        border-radius: 10px;
        opacity: 1;
        background: #d12673;
        transition: all 0.3s;
      }

      .swiper-pagination-bullet-active {
        width: 2.4rem;
        height: 0.8rem;
        opacity: 1;
        background: #0c42f9;
        box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);

        ${media.lessThan('medium')`
          background: ${props => props.theme.colors.brand.primary[10]};
        `}
      }
    }
  }
`

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6rem;
`

export const BtnSwiperPrev = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  border: 0;
  padding: 0;
  color: ${props => props.theme.colors.palette.black};
  background-color: ${alvTokens.ColorPaletteGray160};
  cursor: pointer;

  &:disabled {
    color: #c8c8c8;
  }

  svg {
    font-size: 3.2rem;
  }
`
export const BtnSwiperNext = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  border: 0;
  padding: 0;
  margin-left: 2.3rem;
  color: ${props => props.theme.colors.palette.black};
  background-color: ${alvTokens.ColorPaletteGray160};
  cursor: pointer;

  &:disabled {
    color: #c8c8c8;
  }

  svg {
    font-size: 3.2rem;
  }
`

export const ContainerButtonsCarousel = styled.div`
  width: 90%;

  ${media.lessThan('medium')`
       width: 100%;
       
    `}
`
export const ContainerCardMobileCarousel = styled.div`
  width: 100%;
  height: 100%;

  ${media.lessThan('medium')`
        height: 82px;

        .cnt-cardlink{
          max-height: 48px;
          width: 95%;
          justify-content: space-between;
        }
        
        .textContentCardLink{
          max-width: 350px;
          
          p{
            display: none;
          }
        }
    `}

  ${media.lessThan('450px')`
      .textContentCardLink{
        max-width: 200px;
      }
  `}
`

export const ContainerContentCardsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
