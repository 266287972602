import React, { useState, useEffect, useRef } from 'react'
import * as S from './styled'
import Exam from './Exam'
import Intro from './Intro'
import Sidebar from './Sidebar'
import Search from '../search'
import withLocation from './withLocation'
import { useIsExamsContext } from '../../utils/isExamsContext'
import { NoSearchResult } from '../shared/NoSearchResult'
import PinkLine from '../shared/PinkLine'
import CustomCurrentRefinements from './CurrentRefinement'
import { Filter } from '@dasa-health/alma-icons'
import showExams from '../../utils/showExams'
import Pagination from './Pagination'
import useIsBrazil from '../../utils/isBrazil'
import { CarouselExams } from './CarouselExams'

const Exams = ({ data, slug }) => {
  const isBrazil = useIsBrazil()
  const { 
    specialtyData,
    examsData,
    paginationData,
    isLoading,
    getExamsList,
    getSpecialtyAreas,
    firstRender,
    setFirstRender,
    error,
    handleChangeCheckboxFilter
  } = useIsExamsContext()

  const pageData = data.wordpressWpGatsbyPage.acf

  useEffect(() => {
    if(firstRender) {
      setFirstRender(false)
      getSpecialtyAreas()
      getExamsList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [openFilterMobile, setOpenFilterMobile] = useState(false)
  const examsList = useRef(null)

  function showFiltersMobile(event) {
    event.preventDefault();

    if (openFilterMobile) return setOpenFilterMobile(false)

    setOpenFilterMobile(true)
  }

  const applyFilters = (event) => {    
    event.preventDefault()

    setOpenFilterMobile(false)

    const element = document.getElementById('exams-list');

    if(element) {
      element.scrollIntoView({behavior:"smooth", block: "start", inline:"nearest"});
    }
  };

  const showExamsList = showExams(process.env.GATSBY_COUNTRY)

  useEffect(()=>{

      const specialtyAreaSelected = localStorage.getItem("specialtyAreaSelected")

      if(specialtyAreaSelected){
        handleChangeCheckboxFilter(Number(specialtyAreaSelected),'specialty')
        localStorage.removeItem("specialtyAreaSelected")
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const objectCardExams = Object.keys(data.wordpressWpGatsbyPage.acf)
    .filter(key => key.startsWith("titulo_card")) // Filtra apenas as chaves que começam com "titulo_card"
    .reduce((acc, key) => {
        const numero = key.match(/\d+/)[0]; // Extrai o número do card
        acc.push({
            titulo: data.wordpressWpGatsbyPage.acf[key],
            descricao: data.wordpressWpGatsbyPage.acf[`descricao_card${numero}_exame`] || "Descrição não encontrada",
            url: data.wordpressWpGatsbyPage.acf[`url_card${numero}_exame`] || "#",
        });
        return acc;
    }, []);

  return (
    <>
      <S.ExamsTitleContainer>
          <Intro data={data} />
          <CarouselExams data={objectCardExams} />
      </S.ExamsTitleContainer>

      <Search data={data} isExams />

      <S.FiltersHolder>
        <CustomCurrentRefinements />
      </S.FiltersHolder>

      <S.ExamsContainer>
        <S.ExamsContentHolder id='exams-list'>

          <S.FiltersMobile 
            open={openFilterMobile}
            onClick={showFiltersMobile}>
            <S.FilterChip open={openFilterMobile}>
              {pageData.title_areas_filter_mobile}
            </S.FilterChip>
            <Filter />
          </S.FiltersMobile>

          {specialtyData && (
            <S.ExamsSidebarHolder mobile={openFilterMobile}>
              <Sidebar data={data} />

              <S.BtnApplyFilters onClick={applyFilters}>
                {pageData.title_btn_apply_filter}
              </S.BtnApplyFilters>
            </S.ExamsSidebarHolder>
          )}
          
          <S.ExamsContentList>
            <S.ExamsContent 
              filtersMobile={openFilterMobile}
              ref={examsList}
            >
              {error['exams'] ? (
                <S.TextLoading>Ops! Estamos com problemas, tente novamente mais tarde...</S.TextLoading>
              ) : isLoading ? (
                <S.TextLoading>Carregando...</S.TextLoading>
              ) : showExamsList && examsData && examsData.length > 0 ? (
                examsData.map((item, i) => (
                  <Exam 
                    exam={isBrazil ? item.data : item}
                    key={i}
                    data={data}
                    slug={slug}
                  />
                ))
              ) : (
                <NoSearchResult>Nenhum resultado encontrado.</NoSearchResult>
              )}
            </S.ExamsContent>

            {(!openFilterMobile && !error['exams'] && showExamsList && paginationData.pagesCount) &&
              <Pagination nbPages={paginationData ? paginationData.pagesCount : 0} />
            }
          </S.ExamsContentList>
        </S.ExamsContentHolder>
      </S.ExamsContainer>
  
      <PinkLine />
    </>
  )
}

export default withLocation(Exams)
