import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useIsExamsContext } from '../../utils/isExamsContext'

import * as S from './styled'
import CustomClearRefinements from './ClearRefinement'

const CurrentRefinements = () => {
  const { 
    specialtyData,
    specialtySelected,
    handleChangeCheckboxFilter 
  } = useIsExamsContext()

  const searchSpecialtyName = id => {
   if(specialtyData){
     const result = specialtyData.find(item => item.id === id)
     return result.nome
   }else{
    return ''
   }
  }

  return (
    specialtySelected.length > 0 && (
      <S.RefinementsHolder>
        {specialtySelected.map(id => (
          <S.RefinementsHolder>
            <S.RefinementsItem key={`chip_${id}`}>
              <S.RefinementName
                onClick={() => (
                  handleChangeCheckboxFilter(id, 'specialty')
                )} 
              >
                {searchSpecialtyName(id)}
                <S.RefinementsItemIcon>
                  <FontAwesomeIcon icon={faTimes} />
                </S.RefinementsItemIcon>
              </S.RefinementName>
            </S.RefinementsItem>
          </S.RefinementsHolder>
        ))}
        <S.RefinementsItem>
          <CustomClearRefinements />
        </S.RefinementsItem>
      </S.RefinementsHolder>
    )
  )
}

CurrentRefinements.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func,
  createURL: PropTypes.func,
}

export default CurrentRefinements
