import React, { useMemo, useState } from 'react'
import * as S from './styled'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import { ArrowRight, ArrowLeft } from '@dasa-health/alma-icons'
import CardLink from '../../shared/CardLink'
import { isHuge, isMedium } from '../../../../../utils/src/mediaQuery'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useIsBrazil from '../../../utils/isBrazil'

SwiperCore.use([Autoplay, Pagination, Navigation])

export const CarouselExams = ({ data }) => {
  const navigationPrevRef2 = React.useRef(null)
  const navigationNextRef2 = React.useRef(null)
  const [isOpen, setIsOpen] = useState(true)
  const isBrazil = useIsBrazil() 
  const isHug = useMemo(() => isHuge(), [])
  const isMed = useMemo(() => isMedium(), [])

  return (
    <S.ContainerCarouselExams>
      <S.ContainerTitleCardsExams
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
      >
        <S.TitleCardContentExams>{ isBrazil ? 'Exames em destaque' : 'Exámenes destacados' } </S.TitleCardContentExams>
        <FontAwesomeIcon
          color="#222222"
          title="Fechar"
          size="2x"
          icon={isOpen ? faAngleDown : faAngleUp}
        />
      </S.ContainerTitleCardsExams>

      {isOpen && (
        <S.ContentCarouselExams>
          <Swiper
            className="swiper-two"
            slidesPerView={'auto'}
            grabCursor={true}
            modules={[Pagination, Navigation]}
            breakpoints={{
              0: {
                slidesPerGroup: 1,
                slidesPerView: 1,
              },
              800: {
                slidesPerGroup: 2,
                slidesPerView: 3,
              },
            }}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            navigation={{
              prevEl: navigationPrevRef2.current,
              nextEl: navigationNextRef2.current,
            }}
            onBeforeInit={swiper => {
              swiper.params.navigation.prevEl = navigationPrevRef2.current
              swiper.params.navigation.nextEl = navigationNextRef2.current
            }}
            pagination={{
              clickable: true,
            }}
          >
            {data && data.length > 0 && isHug && !isMed ? (
              data.map((value, index) => (
                <SwiperSlide key={`cardexamSwiper_${Math.random()}_${index}`}>
                  <CardLink
                    key={`card_exam_${Math.random()}_${index}`}
                    title={value.titulo}
                    description={value.descricao}
                    url={value.url}
                  />
                </SwiperSlide>
              ))
            ) : (
              <>
                {[...Array(4)].map((_, index) => (
                  <SwiperSlide key={`cardexamSwiperMobile_${Math.random()}_${index}`}>
                    <S.ContainerContentCardsMobile>
                      <S.ContainerCardMobileCarousel>
                        <CardLink
                          key={`card_exam_${Math.random()}_${index}`}
                          title={index === 0 ? data[index].titulo : data[2 * index + 1].titulo}
                          description={
                            index === 0 ? data[index].descricao : data[2 * index + 1].descricao
                          }
                          url={index === 0 ? data[index].url ?? '' : data[2 * index + 1].url ?? ''}
                        />
                      </S.ContainerCardMobileCarousel>
                      <S.ContainerCardMobileCarousel>
                        <CardLink
                          key={`card_exam_${Math.random()}_${index}`}
                          title={index === 0 ? data[1 + index].titulo : data[index * 2].titulo}
                          description={
                            index === 0 ? data[1 + index].descricao : data[index * 2].descricao
                          }
                          url={index === 0 ? data[1 + index].url ?? '' : data[index * 2].url ?? ''}
                        />
                      </S.ContainerCardMobileCarousel>
                    </S.ContainerContentCardsMobile>
                  </SwiperSlide>
                ))}
              </>
            )}

            <S.ContainerButtonsCarousel>
              <S.BtnsContainer>
                <S.BtnSwiperPrev
                  ref={navigationPrevRef2}
                  aria-label="Voltar para a página interior"
                >
                  <ArrowLeft />
                </S.BtnSwiperPrev>

                <S.BtnSwiperNext ref={navigationNextRef2} aria-label="Ir para a próxima página">
                  <ArrowRight />
                </S.BtnSwiperNext>
              </S.BtnsContainer>
            </S.ContainerButtonsCarousel>
          </Swiper>
        </S.ContentCarouselExams>
      )}
    </S.ContainerCarouselExams>
  )
}
